import * as React from 'react'
import Helmet from 'react-helmet'
import { Link, useStaticQuery, graphql } from 'gatsby'
import './layout.css'

const Layout = ({ pageTitle, snipit, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let finalTitle = pageTitle
  if ( pageTitle !== data.site.siteMetadata.title) {
    finalTitle += " | " + data.site.siteMetadata.title
  }

  return (
    <div className="container py-3">
      <Helmet>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
        <title>{finalTitle}</title>
      </Helmet>

      <header>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
          <Link to="/" className="d-flex align-items-center text-dark text-decoration-none">
            <span className="fs-4">{data.site.siteMetadata.title}</span>
          </Link>

          <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            <Link to="/rates" className="me-3 py-2 text-dark text-decoration-none">Rates</Link>
            <Link to="/contact" className="me-3 py-2 text-dark text-decoration-none">Contact</Link>
            <Link to="/blog" className="me-3 py-2 text-dark text-decoration-none">Blog</Link>
            <Link to="https://justessentialwellness.janeapp.com/" className="py-2 text-dark text-decoration-none">Book Online</Link>
          </nav>
        </div>

        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">{pageTitle}</h1>
          <p className="fs-5 text-muted">{snipit}</p>
        </div>
      </header>

      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout
